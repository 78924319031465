<template>
    <div v-if="item" class="zt-page-content" ref="load">
        <div class="flex flex-v" style="height: 100%;overflow-y: hidden;">
            <div class="flex-1" style="overflow-y:auto;">
                <div class="content">
                    <div class="zt-block">
                        <div class="content-top flex">
                            <div v-if="item.photo_render && item.photo_render.length>0" class="img-carousel">
                                <ItemImages :imgs="item.photo_render" :preUrl="imgUrl" width="400px"></ItemImages>
                            </div>
                            <div class="detail flex-1">
                                <h3 class="item-name ellipsis2">{{item.name}}</h3>
                                <div class="item-series flex">
                                    <div>{{item.style.name}}</div>
                                    <div>{{item.cat.name}}</div>
                                </div>
                                <div v-if="item.design_idea" class="design-idea">
                                    <div>设计理念:</div>
                                    <div class="font14-grey1">{{item.design_idea}}</div>
                                </div>
                                <div class="item-look flex">
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>累计点击</div>
                                    </div>
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>累计下载</div>
                                    </div>
                                    <!-- <div class="look-item">
                                        <div>4</div>
                                        <div>商品询问数</div>
                                    </div> -->
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>已合作的订单</div>
                                    </div>
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>第三方平台上架</div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <compWrap v-if="item.design_comp" :design_comp="item.design_comp" :type="'_bqgm'"></compWrap>
                    <div style="margin-bottom: 0;" class="zt-block flex">
                        <div class="flex-1" style="margin-left:70px;padding:24px 0;">
                            <div class="tab_menu flex">
                                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                                    {{item.name}}
                                </div>
                            </div>
                            <div class="content-box flex">
                                <div class="pic-wrap-left">
                                    <div class="active">
                                        <i></i><span>标高图</span>
                                    </div>
                                    <div class="line"></div>
                                    <div>
                                        <i></i><span>素材图</span>
                                    </div>
                                </div>
                                <div v-if="tabIndex==0">
                                    <div class="content-img">
                                        <div class="ellipsis" style="font-size:22px;color:#808080;padding-top:20px;"
                                            >{{item.name}}</div>
                                        <div v-if="item.dimension" class="size">
                                            <label v-for="(size,idx) in item.dimension" :key="idx">{{size}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                        </div>
                                        <div style="font-size:18px;color:#808080;padding:20px 0 10px 0;">标高图</div>
                                        <div v-if="item.photo_size && item.photo_size.length>0">
                                            <div v-for="(item,idx) in item.photo_size" :key="idx">
                                                <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                            </div>
                                        </div>
                                        <div v-else>
                                            <img src="../../../assets/images/desgin/zwt.png" alt="">
                                        </div>
                                        <div style="font-size:18px;color:#808080;padding:30px 0 20px 0;">素材图</div>
                                        <div v-if="item.photo_story && item.photo_story.length>0">
                                            <div v-for="(item,index) in item.photo_story" :key="index">
                                                <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                            </div>
                                        </div>
                                        <div v-else>
                                            <img src="../../../assets/images/desgin/zwt.png" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tabIndex>0">
                                    <div v-for="(item,idx) in item.accessory" :key="idx">
                                        <div v-if="tabIndex===(idx+1)" class="content-img">
                                            <div class="ellipsis" style="font-size:22px;color:#808080;padding-top:20px;"
                                                >{{item.name}}</div>
                                            <div v-if="item.dimension" class="size">
                                                <label v-for="(size,idx) in item.dimension" :key="idx">{{size}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                            </div>
                                            <div style="font-size:18px;color:#808080;padding:20px 0 10px 0;">标高图</div>
                                            <div v-if="item.photo_size && item.photo_size.length>0">
                                                <div v-for="(item,idx) in item.photo_size" :key="idx">
                                                    <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                                </div>
                                            </div>
                                            <div v-else>
                                                <img src="../../../assets/images/desgin/zwt.png" alt="">
                                            </div>
                                            <div style="font-size:18px;color:#808080;padding:30px 0 20px 0;">素材图</div>
                                            <div v-if="item.photo_story && item.photo_story.length>0">
                                                <div v-for="(item,index) in item.photo_story" :key="index">
                                                    <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                                </div>
                                            </div>
                                            <div v-else>
                                                <img src="../../../assets/images/desgin/zwt.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <ItemBigImg v-if="item && item.id" ref="child" :item="item"></ItemBigImg> -->
                            <div v-if="item &&item.photo_cad" class="content-cad flex">
                                <div>设计CAD文件：</div>
                                <div class="flex-1 flex">
                                    <img src="../../../assets/images/desgin/icon_cad.png" alt="">
                                    <div v-if="item &&item.photo_cad" style="padding-top:50px" class="font14-grey1">{{item.photo_cad.length}}份</div>
                                    <div style="padding-top:35px;margin-left: 20px;">
                                        <a v-if="item && item.trans_stat>6 && item.photo_cad.length>0" :href="imgUrl+item.photo_cad[0]">
                                            <el-button size="small" type="primary" plain>下载</el-button>
                                        </a>
                                        <div v-if="item && item.trans_stat!=11 && item.photo_cad.length>0" style="font-size:14px;color:#F66F6A;padding-top:15px;">购买后可下载</div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div style="width:310px;margin: 24px;">
                            <div class="goods-title">同系列作品</div>
                            <div class="goods-list">
                                <div v-for="(item,idx) in items" :key="idx"  class="item">
                                    <div @click="gotoDetail(item.id)" style="width:230px;height:230px;margin-right:40px;cursor:pointer;">
                                        <div v-if="item.photo_render && item.photo_render.length>0" class="bg_img"
                                        :style="{backgroundImage: 'url(' + imgUrl+item.photo_render[0]+'!width_250px' + ')'}"></div>
                                    </div>
                                    <div class="detail">
                                        <div @click="gotoDetail(item.id)" class="item-name ellipsis">{{item.name}}</div>
                                        <div class="item-series flex">
                                            <div>{{item.style.name}}</div>
                                            <div>{{item.cat.name}}</div>
                                        </div>
                                        <div class="item-price">￥{{item.price | priceFilt}}</div>
                                        <div style="font-size:12px;">已有{{item.design_market_n_visited}}人查看</div>
                
                                    </div>
                                </div>
                            </div>
    
                        </div>
    
                    </div>
                </div>
    
            </div>
            <div class="fixed-bottom">
                <div class="flex flex-align-center">
                    <div style="font-size:32px;color:#EE1818;" class="flex-1 flex flex-align-center">
                        <div>一口价：￥{{item.price | priceFilt}}</div>
                        <el-button v-if="item && item.trans_stat>=2" @click="lookContract(item)" style="margin-left: 20px;" size="small" type="primary" plain>查看合同</el-button>
                    </div>
                    <div class="flex">
                        <div v-if="item && item.trans_stat<=1">
                            <el-button @click="gotoSign(item)" type="primary">我要购买</el-button>
                        </div>
                        <div v-if="item && item.trans_stat>=2 && item.trans_stat<4" style="font-size:24px;color:#2BBAAE;">等待对方签约</div>
                        <div v-if="item && item.trans_stat==4" style="font-size:24px;color:#2BBAAE;">双方已签约</div>
                        <div v-if="item && item.trans_stat==5" style="font-size:24px;color:#2BBAAE;">待对方确认收款</div>
                        <div v-if="item && item.trans_stat>=6 && item.trans_stat<=11" style="font-size:24px;color:#2BBAAE;">交易完成</div>
                        <div v-if="item && item.trans_stat==21" style="font-size:24px;color:rgb(246, 111, 106);">卖方已修改商品信息</div>
                        <div v-if="item && item.trans_stat==22" style="font-size:24px;color:rgb(246, 111, 106);">库存不足</div>
                        <div v-if="item && item.trans_stat==29" style="font-size:24px;color:rgb(246, 111, 106);">已有工厂签约</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="sign_load flex flex-align-center">
            <div class="loading-spinner">
                <svg viewBox="25 25 50 50" class="circular">
                    <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
                </svg>
                <div class="loading-text">拼命加载中...</div>
            </div>
        </div> -->

        <el-dialog width="500px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <div v-if="popTitle == '签约'">
                <Sign :popTitle="popTitle" @data="data" @signState="signState" @hide="hide"></Sign>
            </div>
            <div v-if="popTitle == '遇到问题'">
                <SignBack :popTitle="popTitle" @hide="popVisible=false"></SignBack>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import { Loading } from 'element-ui';
    import { IMG_URL_PRE } from '@/config';
    import { marketPriceList,facPriceId } from '@/service/factory';
    import { goSign,contractAddr,contractView } from '@/service/contract';
    import ItemImages from '@/components/ItemImages.vue';
    import Sign from '@/components/sign/sign.vue';
    import SignBack from '@/components/sign/signBack.vue';
    import compWrap from '../Zysp/compWrap.vue';
    // import ItemBigImg from '@/components/factory/ItemBigImg.vue';
    export default {
        components: {
            ItemImages,
            Sign,SignBack,
            compWrap,
            // ItemBigImg
        },
        props:{},
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                popVisible: false,
                popTitle: null,
                tabList:[
                    {name:"主作品"},
                ],
                tabIndex:0,
                colorIndex:0,
                item:null,
                queryOpt: {
                    pagesize: 3,
                    page: 1,
                },
                total:0,
                items:[],
                invsec:20,// 签约相关倒计时
                timer:null,
                loading:null,
            }
        },
        watch: {
            $route(to,from){
                this.item = null;
                this.data();
                this.refreshItems();
            }
        },
        created:function() {
            this.data();
            this.refreshItems();
        },
        async mounted() {
            
        },
        methods: {
            // scroll(e) {
            //     this.$refs.child.scroll(e);
            // },
            data() {
                facPriceId(this.$route.params.id).then(rst=>{
                    if (rst) {
                        this.item=rst;
                        if (this.item.accessory && this.item.accessory.length>0) {
                            this.item.accessory.forEach(e => {
                                this.tabList.push({name:e.name});
                            });
                        }
                    }
                    console.log(this.item);
                });
                
            },
            refreshItems() {
                console.log('refreshItems');
                marketPriceList(this.queryOpt).then(rst => {
                    if (rst.list && rst.list.length>0) {
                        this.total=rst.total;
                        this.items = rst.list;
                    }
                    console.log(this.items);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            goto(path){
                this.$router.push(path);
            },
            gotoDetail(id) {
                this.$router.push("/factory/gmsj/bqxs/detail/"+id);
            },
            tab(index) { // 选项卡
                this.tabIndex = index;
                console.log(index);
            },
            // 查看合同
            lookContract(item) {
                if (item.contract_no) {
                    window.open(contractView(item.contract_no));
                }
            },
            gotoSign(item) {
                this.$confirm('确认是否发起签约?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消',closeOnClickModal: false, type: 'warning',
                }).then(() => {
                    goSign(item.id).then(rst=>{
                        this.going(rst.contract_no,rst.transaction_no);
                    }).catch(err=>{
                        this.$message.error(err.message);
                    });
                });
            },
            going(contract_no,transaction_no) {
                this.loading = Loading.service({
                    fullscreen: true, text: '拼命加载中....',
                    target:this.$refs.load
                });
                // this.invsec = 30;
                this.timer = setInterval(() => {
                    contractAddr(contract_no).then(rst=>{
                        console.log(rst);
                        if (rst && rst.url) {
                            this.sign(rst.url);
                            clearInterval(this.timer);
                            return;
                        }
                    });
                    // if (this.invsec == 0) {
                    //     // this.goCgdd();
                    //     window.location.href="about:blank";
                    //     window.close();
                    //     clearInterval(this.timer);
                    //     return;
                    // }
                    // this.invsec--;
                }, 2000);
            },
            sign(url) {
                this.loading.close();
                if(url) window.open(url);
                this.signState("签约");
            },
            hide() {
                this.popVisible = false;
                this.$message.success('状态已更新');
            },
            signState(text) {
                this.popTitle = text;
                this.popVisible = true;
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped src="../detail.css"></style>
<style scoped>
    .zt-page-content {
        position: relative;
    }
    .content {
        /* width: 80%; */
        min-width: 1360px;
        margin: 0 auto;
        padding:0 30px;
        background: transparent;
    }
    .content-top {
        padding: 64px 84px 45px;
    }
    .content-top .detail {
        margin-left: 45px;
        position: relative;
    }
    .content-top .item-name {
        font-size: 24px;
        font-weight: bold;
        color: #292929;
        line-height: 28px;
        margin: 12px 0;
    }
    .content-top .item-series {
        font-size: 14px;
        color: #808080;
        line-height: 18px;
        margin-bottom: 54px;
    }
    .content-top .item-series>div {
        padding: 0 24px;
        border-right: 1px solid #CCCCCC;
    }
    .content-top .item-series>div:first-child {
        padding-left: 0;
    }
    .content-top .item-series>div:last-child {
        padding-right: 0;
        border-right: none;
    }
    .content-top div.design-idea {
        max-width: 650px;
        padding: 8px 16px 16px 16px;
        background: rgba(43,186,174,0.05);
    }
    .content-top .design-idea>div:first-child {
        font-size: 16px;
        color: #4D4D4D;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .content-top .item-look {
        position: absolute;
        left: 0;
        bottom: 87px;
    }
    .content-top .item-look .look-item {
        min-width: 128px;
        height: 70px;
        text-align: center;
        padding: 8px 0;
        border-radius: 4px;
        background:rgba(255,145,0,0.1);
        margin-right: 20px;
    }
    .content-top .look-item>div:first-child {
        font-size: 24px;
        color: #F66F6A;
        line-height: 33px;
    }
    .content-top .look-item>div:last-child {
        font-size: 16px;
        color: #4D4D4D;
        line-height: 22px;
    }
    /* -------------工厂信息-------------- */
    .facList {
        padding: 20px 30px;
        border:1px solid rgba(204,204,204,1);
        box-shadow:0px 3px 6px rgba(0,0,0,0.16);
        border-radius:6px;
    }
    .facList-left {
        /* padding-right: 60px;
        border-right: 1px solid #CCCCCC; */
    }
    .fac-label {
        max-width: 400px;
    }
    .fac-label>div {
        font-size: 14px;
        color: #016FFF;
        background:rgba(1,111,255,0.2);
        border-radius: 12px;
        padding: 0 16px;
        height: 24px;
        line-height: 24px;
        margin-right: 16px;
        margin-bottom: 6px;
    }
    .fac-label>div:nth-child(4n) {
        margin-right: 0;
    }
    .fac-label>div:nth-child(2),.fac-label>div:nth-child(7) {
        background:rgba(43,186,174,0.2);
        color: #2BBAAE;
    }
    .fac-label>div:nth-child(3),.fac-label>div:nth-child(8) {
        background:rgba(230,162,60,0.2);
        color: #E6A23C;
    }
    .fac-label>div:nth-child(4),.fac-label>div:nth-child(5) {
        background:rgba(246,111,106,0.2);
        color: #F66F6A;
    }

    /* ----------弹框---------- */
    div.container>>> .el-dialog__body {
        background: #F5F5F5;
    }
    div.container>>> .el-dialog {
        min-width: 600px;
    }

</style>
<style scoped>
    /* -------右边同系列作品-------- */
    .goods-title {
        font-size:20px;
        color:#4D4D4D;
        line-height:90px;
        background: #E6E6E6;
        text-align: center;
        margin-bottom:5px;
    }
    .goods-list {
        background: #F5F5F5;
        box-shadow:0px 3px 6px rgba(0,0,0,0.16);
        overflow: hidden;
        border-radius: 2px;
    }
    .goods-list .item{
        width: 230px;
        overflow: hidden;
        border:1px solid rgba(204,204,204,1);
        margin: 30px auto;
        background: #fff;
    }
    .goods-list .item .detail {
        padding:8px 13px;
        position: relative;
        font-size: 14px;
        color: #808080;
    }
    .goods-list .item .item-name {
        font-size: 16px;
        font-weight: bold;
        color: rgba(41, 41, 41, 1);
        line-height:22px;
        margin-bottom: 4px;
    }
    .goods-list .item-series {
        font-size: 14px;
        color: #808080;
        line-height: 18px;
        margin-bottom: 4px;
    }
    .goods-list .item-series>div {
        padding: 0 10px;
        border-right: 1px solid #CCCCCC;
    }
    .goods-list .item-series>div:first-child {
        padding-left: 0;
    }
    .goods-list .item-series>div:last-child {
        padding-right: 0;
        border-right: none;
    }
    .goods-list .item-price {
        font-size: 20px;
        color: #EE1818;
        line-height: 28px;
    }
    .goods-list .item-progress {
        width: 91px;
        height: 20px;
        line-height: 20px;
        background: #E6E6E6;
        font-size: 12px;
        color: #fff;
        position: relative;
        margin-right: 10px;
        margin-bottom: 8px;
    }
    .goods-list .item-progress>div:first-child {
        height: 20px;
        background: #E6A23C;
    }
    /* ------底部------ */
    .fixed-bottom {
        width: 100%;
        min-width: 1360px;
        height: 108px;
        background:rgba(230,230,230,1);
    }
    .fixed-bottom>div {
        padding: 30px 0;
        width: 1260px;
        margin: 0 auto;
    }
</style>
<style scoped>
    .sign_load {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        z-index: 1;
        background-color: hsla(0,0%,100%,.9);
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity .3s;
    }
    .loading-spinner {
        width: 100%;
        text-align: center;
    }
    .loading-spinner .circular {
        height: 42px;
        width: 42px;
        animation: loading-rotate 2s linear infinite;
    }
    .loading-spinner .path {
        animation: loading-dash 1.5s ease-in-out infinite;
        stroke-dasharray: 90,150;
        stroke-dashoffset: 0;
        stroke-width: 2;
        stroke: #24B1EB;
        stroke-linecap: round;
    }
    .loading-spinner .loading-text {
        color: #24B1EB;
        margin: 3px 0;
        font-size: 14px;
    }
</style>